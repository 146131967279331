import React, { useEffect, useState } from 'react'
import Navbar from '../../components/NavBar'
import Footer from '../../components/Footer/footer'
import QuotesSearch from './QuotesSearch'
import "./SearchCard.css";
import { Col, Row } from 'react-bootstrap';
import axios from 'axios';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';


const QuotesList = () => {
const [allQuotes,setAllQutoes]=useState([])


    const getData =async ()=>{
        try {
            const res =await axios.get('https://backend.myplotpic.com/api/quote')
            setAllQutoes(res.data)

        } catch (error) {
            
        }
    }

    useEffect(()=>{
        getData()
    },[])

 
  return (
    <div> 


<div>
        <div className="con-box">
        <Navbar />
        <div>

        <header>
      <div class='container container-flex'>
        <div class='site-title' style={{width:'50%'}}>
          <h1>Living The Social Life</h1>
          <p class='subtitle'>A Quotes exploring minimalism in life.</p>
        </div>
        <nav style={{width:'50%'}}>
        <QuotesSearch/> 
        </nav>
      </div>
    </header> 
    {/* <!-- header ends --> */}
    
    <section class="gallery">
    {allQuotes && allQuotes.map((item, index) => (
  <div className="image my-1" key={index}  >
    <Link to={`/quotes/${item?._id}`}>
    <img
      src={`https://backend.myplotpic.com/${item?.watermarkedImage}`}
      alt="image"
      loading="lazy" // Lazy loading attribute
      />
      </Link>
  </div>
))}

  
</section>

        </div>
       
        </div>
        <Footer />
      </div>

    </div>
  )
}

export default QuotesList